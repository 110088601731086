import { Carousel } from  '@fancyapps/ui/dist/carousel/carousel.esm.js';
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";
import "../scss/theme.scss";
const carousel_container = document.getElementById("hero_carousel");
const options = { 
	infinite: true, 
	Autoplay: {
		timeout: 3200,
		showProgress: true
	}, 
    transition: "crossfade",
	Dots: false, 
	// Navigation: false
};

if (carousel_container)
    new Carousel(carousel_container, options, { Autoplay });
var isTouch = window.DocumentTouch && document instanceof DocumentTouch;

function scrollHeader() {
    // Has scrolled class on header
    var zvalue = $(document).scrollTop();
    if ( zvalue > 75 )
        $("#header").addClass("scrolled");
    else
        $("#header").removeClass("scrolled");
}

function parallaxBackground() {
    $('.parallax').css('background-positionY', ($(window).scrollTop() * 0.3) + 'px');
}

Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });
Fancybox.bind("[rel='lightbox']", {
    // Your custom options
});

jQuery(document).ready(function($){

    if (document.querySelector('input[name="data[deelname]"]')) {
        document.querySelectorAll('input[name="data[deelname]"]').forEach((el) => {
            el.addEventListener('change', function(e) {
                if (document.querySelector('input[name="data[deelname]"]:checked').value == "nee") {
                    document.querySelector('input[name="data[betaald]"]').removeAttribute('required');
                    document.querySelector('input[name="data[betaald]"]').parentElement.querySelector('span.required').style.visibility = 'hidden';
                    document.querySelector('input[name="data[sol-akkoord]"]').removeAttribute('required');
                    document.querySelector('input[name="data[sol-akkoord]"]').parentElement.querySelector('span.required').style.visibility = 'hidden';
                } else {
                    document.querySelector('input[name="data[betaald]"]').setAttribute('required', 'required');
                    document.querySelector('input[name="data[betaald]"]').parentElement.querySelector('span.required').style.visibility = 'visible';
                    document.querySelector('input[name="data[sol-akkoord]"]').setAttribute('required', 'required');
                    document.querySelector('input[name="data[sol-akkoord]"]').parentElement.querySelector('span.required').style.visibility = 'visible';
                }
            })
        })
    }


    scrollHeader();

    // Scroll Events
    if (!isTouch){
        $(document).scroll(function() {
            scrollHeader();
            parallaxBackground();
        });
    };

    // Touch scroll
    $(document).on({
        'touchmove': function(e) {
            scrollHeader(); // Replace this with your code.
        }
    });

    //Smooth scroll to start
    $('#to-start').click(function(){
        var start_y = $('#start').position().top;
        var header_offset = 45;
        window.scroll({ top: start_y - header_offset, left: 0, behavior: 'smooth' });
        return false;
    });

    //Smooth scroll to top
    $('#to-top').click(function(){
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        return false;
    });

    // Responsive Menu
    $('#toggle').click(function () {
        $(this).toggleClass('active');
        $('#overlay').toggleClass('open');
        $('body').toggleClass('mobile-nav-open');
    });

    // Tree Menu
    $(".tree").treemenu({delay:300});

    // $(".toggle-slide").click(function(e){

    //     e.preventDefault();

    //     var slideTarget = $($(this).data("element"))

    //     // console.log(targets);
    //     slideTarget.slideToggle("slow");
    //     if (slideTarget.hasClass('open') == false) {
    //       var scrollTarget = $($(this).data("scroll-to"))
    //       console.log(scrollTarget);
    //       var header_offset = 45;
    //       var start_y = scrollTarget.offset().top;
    //       console.log(start_y);
    //       window.scroll({ top: start_y - header_offset, left: 0, behavior: 'smooth' });
    //     }
    //     slideTarget.toggleClass('open')
    //     // var start_y = targets.position().top;
    //     // console.log(start_y);
    //     // var header_offset = 45;
    //     // console.log(header_offset);

    // });
    

});
